import { forwardRef } from 'react';

const FormInput = forwardRef(
  (
    { as, type, label, children, className, messageCharsLeft, ...props },
    ref
  ) => {
    const As = as;

    return (
      <div className={`${className} form-input mb-4 md:mb-8`}>
        <label className="text-white capitalize text-sm lg:text-lg">
          {label} {type === 'textarea' ? <span>({messageCharsLeft})</span> : ''}
        </label>
        <As
          name={label}
          autoComplete="off"
          ref={ref}
          className={`${
            type === 'textarea' ? 'pb-20 block' : ''
          } w-full mt-4 py-4 px-6 text-white focus:outline-none bg-blue-dark`}
          {...props}
        />
        {children}
      </div>
    );
  }
);

FormInput.defaultProps = {
  type: 'text',
  as: 'input',
};

export default FormInput;
