import { ErrorMessage as HErrorMessage } from '@hookform/error-message';

const ErrorMessage = (props) => {
  return (
    <HErrorMessage
      {...props}
      render={({ message }) => (
        <div className="mt-2 text-sm md:text-lg text-red capitalize">
          {message}
        </div>
      )}
    />
  );
};

export default ErrorMessage;
