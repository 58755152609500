import React from 'react';
import { PORTFOLIO_DATA } from './project.data';

import Heading from '../Heading';
import ProjectCard from './ProjectCard';

const PortfolioProjects = () => (
  <div className="bg-black py-12 lg:py-24">
    <div className="container mx-auto px-5 sm:px-10">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-7">
        <div>
          <Heading title="My Projects" className="max-w-xl mb-5 lg:mb-10">
            Few projects i have developed over the years
          </Heading>
          {PORTFOLIO_DATA.slice(0, 3).map((project, idx) => (
            <ProjectCard key={idx} {...project} />
          ))}
        </div>
        <div>
          {PORTFOLIO_DATA.slice(3, 6).map((project, idx) => (
            <ProjectCard key={idx} {...project} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default PortfolioProjects;
