import { motion } from 'framer-motion';

import AboutHeader from '../components/AboutHeader';
import Comments from '../components/comments/Comments';

const AboutPage = () => (
  <motion.div
    initial={{ y: 0 }}
    animate={{ y: 0 }}
    exit={{ y: 40, opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    <AboutHeader />
    <Comments />
  </motion.div>
);

export default AboutPage;
