import React, { useState } from 'react';
import { ReactComponent as Logo } from '../assets/svgs/logo-white.svg';

const Loader = () => {
  const [reveal, setReveal] = useState(false);

  return (
    <div className="clear overflow-hidden">
      <div
        className={`${
          reveal ? 'hide h-0' : 'h-screen'
        } flex text-white justify-center z-30 overflow-hidden flex-col items-center w-screen fixed bg-black loader`}
      >
        <Logo className="w-20 h-20" />
        <div className="block overflow-hidden -mt-6">
          <h1 className="text-3xl xl:text-5xl font-serif font-semibold">
            Hi, &#9995; am {`<Paul />`}
          </h1>
        </div>
        <div className="block overflow-hidden mt-6">
          <p className="max-w-xl mb-12 text-center text-sm xl:text-base">
            I enjoy building great designs using tech
          </p>
        </div>
        <button
          onClick={() => {
            setReveal(true);
            document.getElementById('root').className = 'reset';
          }}
          className="text-sm bg-black overflow-hidden text-white h-14 border text-center w-48 md:tracking-widest hover:bg-white hover:shadow-xl transform btn hover:text-black focus:outline-none font-bold"
        >
          <span>Explore</span>
        </button>
      </div>
      <div
        className={`${
          reveal ? 'h-0' : 'h-screen'
        }  z-20 overflow-hidden w-screen fixed bg-gray-400 loader loader2`}
      ></div>
    </div>
  );
};

export default Loader;
