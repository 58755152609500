import React from 'react';

import Button from './Button';
import Heading from './Heading';
// Svgs
import { ReactComponent as StoryBook } from '../assets/svgs/Rotating/storybook.svg';
import { ReactComponent as Js } from '../assets/svgs/Rotating/js.svg';
import { ReactComponent as Boostrap } from '../assets/svgs/Rotating/boostrap.svg';
import { ReactComponent as Vue } from '../assets/svgs/Rotating/vuejs.svg';
import { ReactComponent as Git } from '../assets/svgs/Rotating/git.svg';
import { ReactComponent as Sass } from '../assets/svgs/Rotating/sass.svg';
import { ReactComponent as Tailwind } from '../assets/svgs/Rotating/tailwind.svg';
import { ReactComponent as Figma } from '../assets/svgs/Rotating/figma.svg';
import { ReactComponent as ReactLogo } from '../assets/svgs/Rotating/react.svg';

import CV from '../assets/cv.pdf';

const CTA = () => {
  const icons_cirlcle = [StoryBook, Js, Boostrap, Vue, Git, Sass];
  const icons_inner_cirle = [Tailwind, Figma, ReactLogo];

  return (
    <div className="bg-black py-16">
      <div className="container mx-auto px-10">
        <div className="grid md:grid-cols-1 lg:grid-cols-2">
          <div className="text-center lg:text-left">
            <Heading title="My Skills">Awesome Skillset</Heading>
            <p className="text-sm xl:text-lg mt-4 text-white mx-auto lg:mx-0 max-w-md">
              I use a variety of skills to accomplish my work effectively,
              leaving my clients estatic to working again with me.
            </p>
            <a href={CV} download>
              <Button className="border border-gray-600 mt-6 lg:mt-16">
                Download Resume
              </Button>
            </a>
          </div>
          <div className="hidden lg:block relative">
            <div className="circle">
              {icons_cirlcle.map((Icon, idx) => (
                <Icon key={idx} className="item" />
              ))}
            </div>
            <div className="circle-container absolute top-10 left-1/2 transform -translate-x-1/2 rounded-full">
              <div className="circle-2">
                {icons_inner_cirle.map((Icon, idx) => (
                  <Icon key={idx} className="item" />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
