import Flickity from 'react-flickity-component';

import Bookings from '../assets/images/carousel/bookings.webp';
import Afaara from '../assets/images/carousel/afaara.webp';
import Hallers from '../assets/images/carousel/haullersonline.webp';
import Wusob from '../assets/images/carousel/wusob.webp';
import Geoplex from '../assets/images/carousel/geoplex.webp';

const flickityOptions = {
  initialIndex: 2,
  autoPlay: true,
  pauseAutoPlayOnHover: false,
  contain: true,
};

const Carousel = () => (
  <Flickity
    className={'carousel bg-black z-10 relative focus:outline-none'}
    elementType={'div'}
    options={flickityOptions}
    disableImagesLoaded={false}
  >
    <div className="bg-black w-full mr-5 md:mr-0 md:w-1/3 xl:w-1/4 2xl:w-1/6 h-20">
      <img
        src={Bookings}
        alt="Bookingsafrica Carousel"
        className="h-10 mt-5 mx-auto"
      />
    </div>
    <div className="bg-black w-full mr-5 md:mr-0 md:w-1/3 xl:w-1/4 2xl:w-1/6">
      <img src={Afaara} alt="Afaara Carousel" className="h-10 mt-4 mx-auto" />
    </div>
    <div className="bg-black w-full mr-5 md:mr-0 md:w-1/3 xl:w-1/4 2xl:w-1/6">
      <img
        src={Hallers}
        alt="HallersOnline Carousel"
        className="h-12 mt-4 mx-auto"
      />
    </div>
    <div className="bg-black w-full mr-5 md:mr-0 2xl:ml-10 md:w-1/3 xl:w-1/4 2xl:w-1/6">
      <img
        src={Wusob}
        alt="Wusob Tech Carousel"
        className="h-16 mt-2 mx-auto"
      />
    </div>
    <div className="bg-black w-full mr-5 md:mr-0 md:w-1/3 xl:w-1/4 2xl:w-1/6">
      <img
        src={Geoplex}
        alt="Geoplex Drillings Carousel"
        className="h-16 mt-2 mx-auto"
      />
    </div>
  </Flickity>
);

export default Carousel;
