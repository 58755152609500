import React from 'react';
import { motion } from 'framer-motion';

import BaseHeader from '../components/BaseHeader';
import Skills from '../components/skills/Skills';
import Quirks from '../components/Quirks';

const SkillsPage = () => (
  <motion.div
    initial={{ y: 0 }}
    animate={{ y: 0 }}
    exit={{ y: 40, opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    <BaseHeader title="My Skills" caption="Skills & Experience" />
    <Quirks />
    <Skills />
  </motion.div>
);

export default SkillsPage;
