import React from 'react';

import Navbar from './Navbar';
import ProfileImage from '../assets/images/bg2.webp';

const AboutHeader = () => {
  return (
    <aside>
      <header className="header overflow-hidden z-10 relative bg-black">
        <div className="container z-10 mx-auto px-5 sm:px-10">
          <div className="scroll absolute bottom-32 -right-5 lg:right-2 transform rotate-90">
            <h1 className="text-white text-sm lg:text-base">
              Scroll Down <span className="ml-3">&#8594;</span>
            </h1>
          </div>
          <Navbar type="white" className="pt-10" />
          <div className="grid grid-cols-1 lg:grid-cols-2 h-full">
            <div className="h-full hidden lg:block relative">
              <img
                src={ProfileImage}
                alt="Paul Emas Profile banner 2"
                className="h-auto object-contain relative xl:top-20 2xl:top-14 -left-10"
              />
            </div>
            <div className="py-60 lg:py-40 xl:py-72 2xl:py-84">
              <div className="space-y-4 xl:space-y-8  text-center lg:text-left text-white">
                <h1 className="text-5xl xl:text-6xl font-serif font-bold">
                  Philosophy & values
                </h1>
                <p className="text-sm mx-auto lg:mx-0 max-w-sm md:max-w-md xl:text-lg xl:max-w-xl">
                  I think some people want the same thing - relationship with
                  humanity, peace with the metaphysical, and experience with the
                  universe. I try to grasp these things with my values:
                  authenticity, creativity, & hospitality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </aside>
  );
};

export default AboutHeader;
