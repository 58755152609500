import React from 'react';

import Heading from '../Heading';
import SkillCard from './SkillCard';

import SKILL_DATA from './skills.data';

const Skills = () => (
  <div className="bg-black py-20 xl:py-20">
    <div className="container mx-auto px-5 sm:px-10">
      <Heading title="Technologies">Currently Using:</Heading>
      <div className="h-0 w-0 overflow-hidden lg:w-auto lg:h-auto lg:overflow-auto">
        <div className="flex justify-evenly md:justify-center mt-10 md:mt-20 md:gap-x-5 lg:gap-x-8 xl:gap-x-20">
          {SKILL_DATA.slice(0, 3).map((skill) => (
            <SkillCard skill={skill} />
          ))}
        </div>
        <div className="flex justify-evenly md:justify-center mt-5 md:mt-10 md:gap-x-10 xl:gap-x-24">
          {SKILL_DATA.slice(3, 8).map((skill) => (
            <SkillCard skill={skill} />
          ))}
        </div>
        <div className="flex justify-evenly md:justify-center mt-5 md:mt-10 gap-x-2 md:gap-x-20 xl:gap-x-32">
          {SKILL_DATA.slice(8, 13).map((skill) => (
            <SkillCard skill={skill} />
          ))}
        </div>
      </div>
      <div className="block lg:hidden">
        <div className="flex flex-wrap justify-evenly md:justify-center mt-10 md:mt-20 md:gap-x-5 lg:gap-x-8 xl:gap-x-20">
          {SKILL_DATA.map((skill) => (
            <SkillCard skill={skill} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Skills;
