const Button = ({ className, children, theme, ...props }) => {
  const mode =
    theme === 'light' ? 'bg-white text-black' : 'bg-black text-white';
  return (
    <button
      {...props}
      className={`${className} ${mode} btn text-sm xl:text-lg py-4 xl:py-5 px-16 xl:px-20 md:tracking-widest hover:bg-white duration-300 hover:shadow-xl transform active:scale-95 hover:text-black focus:outline-none font-bold`}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  className: '',
  theme: 'black',
};

export default Button;
