import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/svgs/logo-black.svg';
import { ReactComponent as Gmail } from '../assets/svgs/Footer/gmail-sm.svg';
import { ReactComponent as GitHub } from '../assets/svgs/Footer/github-sm.svg';
import { ReactComponent as Twitter } from '../assets/svgs/Footer/twitter-sm.svg';
import { ReactComponent as Instagram } from '../assets/svgs/Footer/instagram-sm.svg';

const Footer = () => {
  const socialIcons = [
    { icon: Gmail, link: 'mailto:emaspaul1526@gmail.com' },
    { icon: GitHub, link: 'https://github.com/paul-emas' },
    { icon: Twitter, link: 'https://twitter.com/paul_emas' },
    { icon: Instagram, link: 'https://www.instagram.com/paul.emas/' },
  ];

  return (
    <footer className="bg-gray-200 py-5">
      <div className="container mx-auto px-5 sm:px-10">
        <Logo className="mx-auto" />
        <nav className="mx-auto text-center text-sm py-10 border-b border-gray-400 font-light font-serif space-x-5 md:space-x-8">
          <Link to="/">Home</Link>
          <Link to="/about">About me</Link>
          <Link to="/skills">Skills</Link>
          <Link to="/works">Works</Link>
        </nav>
        <div className="flex justify-center lg:justify-between flex-wrap mt-5 md:mt-7">
          <p className="mb-6">
            Designed and Built with &#10084;&#65039; by Paul Emas
          </p>
          <div className="flex space-x-4">
            {socialIcons.map(({ icon, link }, idx) => {
              const Icon = icon;
              return (
                <a
                  key={idx}
                  target="_blank"
                  rel="noreferrer"
                  href={link}
                  className="w-10 h-10 rounded-full bg-gray-300"
                >
                  <Icon className="mx-auto relative top-2.5" />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
