import React from 'react';
import { Link } from 'react-scroll';

import Navbar from './Navbar';
import Button from './Button';
import ProfileImage from '../assets/images/bg.webp';

const Header = () => {
  return (
    <aside>
      <header className="header overflow-hidden z-10 relative bg-gray-200">
        <div className="container z-10 mx-auto px-5 sm:px-10">
          <div className="scroll absolute bottom-28 -right-5 lg:right-2 transform rotate-90">
            <h1 className="text-black lg:text-white text-sm lg:text-base">
              Scroll Down <span className="ml-3">&#8594;</span>
            </h1>
          </div>
          <Navbar type="black" className="pt-10" />
          <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 h-full">
            <div className="py-48 md:py-52 lg:py-36 xl:py-48 2xl:py-64 col-span-1 text-center lg:text-left">
              <div className="space-y-6 xl:space-y-8">
                <span className="text-2xl md:text-3xl xl:text-4xl font-serif font-bold">
                  Hi, I am
                </span>
                <h1
                  data-text="Paul Emas"
                  className="text-5xl md:text-6xl lg:text-5xl xl:text-7xl font-serif font-bold"
                >
                  Paul Emas
                </h1>
                <p className="text-lg md:text-xl xl:text-2xl font-serif text-grey">
                  Front-end Developer / UI Developer
                </p>
              </div>
              <Link to="contact" spy={true} smooth={true}>
                <Button className="mt-8 lg:mt-8 xl:mt-16">Hire Me</Button>
              </Link>
            </div>
            <div className="hidden lg:block lg:h-full relative xl:col-span-2">
              <img
                src={ProfileImage}
                alt="Paul Emas Profile banner"
                className="h-auto object-contain relative lg:top-24 xl:top-10"
              />
            </div>
          </div>
        </div>
      </header>
    </aside>
  );
};

export default Header;
