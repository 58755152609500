import React from 'react';
import { motion } from 'framer-motion';

import BaseHeader from '../components/BaseHeader';
import PortfolioProjects from '../components/projects/PortfolioProjects';

const WorkPage = () => (
  <motion.div
    initial={{ y: 0 }}
    animate={{ y: 0 }}
    exit={{ y: 40, opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    <BaseHeader title="Works" caption="work experience" />
    <PortfolioProjects />
  </motion.div>
);

export default WorkPage;
