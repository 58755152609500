import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/svgs/logo-black.svg';
import { ReactComponent as LogoWhite } from '../assets/svgs/logo-white.svg';
import { ReactComponent as GitHub } from '../assets/svgs/Footer/github-white.svg';

const Navbar = ({ type, className }) => {
  const mode = type === 'white' ? 'text-white' : 'lg:text-white';

  return (
    <nav className={className}>
      <div className="flex justify-between items-center">
        <Link to="/">
          {type === 'white' ? (
            <LogoWhite className="h-10 md:h-16" />
          ) : (
            <Logo className="h-10 md:h-16" />
          )}
        </Link>
        <div
          className={`${mode} links flex text-xs md:text-sm xl:text-lg font-serif font-semibold space-x-5 md:space-x-10 lg:space-x-16`}
        >
          <Link to="/" className="cursor-pointer">
            Home
          </Link>
          <Link to="/about" className="cursor-pointer">
            About me
          </Link>
          <Link to="/skills" className="cursor-pointer">
            Skills
          </Link>
          <Link to="/works" className="cursor-pointer">
            Works
          </Link>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/paul-emas"
            className="hidden md:block "
          >
            <GitHub className="mx-auto relative md:w-8 md:h-8 -top-1" />
          </a>
        </div>
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  type: 'white',
};

export default Navbar;
