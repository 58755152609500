import React from 'react';
import COMMENT_DATA from './comment.data';

import Heading from '../Heading';
import CommentCard from './CommentCard';

const Comments = () => (
  <div className="bg-black pt-24 md:pb-10 lg:pb-20 xl:pb-20">
    <div className="container mx-auto px-5 sm:px-10">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-7">
        <div className="relative z-10">
          <Heading className="max-w-md comment-quote">
            Real Stories from Colleagues
          </Heading>
          <p className="text-lg text-white mt-4">
            Get inspired by these stories.
          </p>
          <div className="lg:max-w-sm lg:float-right mt-8">
            {COMMENT_DATA.slice(0, 2).map((comment) => (
              <CommentCard key={comment.id} {...comment} />
            ))}
          </div>
        </div>
        <div>
          <div className="lg:max-w-md lg:mt-8">
            {COMMENT_DATA.slice(2, 4).map((comment) => (
              <CommentCard key={comment.id} {...comment} />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Comments;
