import React from 'react';
import Carousel from '../Carousel';
import Heading from '../Heading';

const Works = () => (
  <div className="bg-black py-8 lg:py-12">
    <div className="container mx-auto works px-10">
      <Heading
        title="Work Expericence"
        className="max-w-xl text-center md:text-left mb-6 lg:mb-10"
      >
        Companies i have worked with
      </Heading>
      <Carousel />
    </div>
  </div>
);

export default Works;
