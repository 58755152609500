import React from 'react';
import { PROJECT_DATA } from './project.data';

import Heading from '../Heading';
import ProjectCard from './ProjectCard';
import Button from '../Button';
import { Link } from 'react-router-dom';

const Projects = () => (
  <div className="bg-black py-12 lg:py-24">
    <div className="container mx-auto px-5 sm:px-10">
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-7">
        <div>
          <Heading title="My Projects" className="max-w-xl mb-5 lg:mb-10">
            I’m a Dev who work hands-on
          </Heading>
          {PROJECT_DATA.slice(0, 2).map((project, idx) => (
            <ProjectCard key={idx} {...project} />
          ))}
        </div>
        <div>
          {PROJECT_DATA.slice(2, 4).map((project, idx) => (
            <ProjectCard key={idx} {...project} />
          ))}
          <div className="text-center">
            <Link to="/works">
              <Button className="border border-gray-600">See More</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Projects;
