import Andela from '../../assets/images/logos/andela.webp';
import Uba from '../../assets/images/logos/uba.webp';
import Enyata from '../../assets/images/logos/enyata.webp';
import Bookingsafrica from '../../assets/images/logos/bookingsafrica.webp';

const COMMENT_DATA = [
  {
    id: 0,
    logo: Andela,
    comment:
      'Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work is to love what you do. If you haven’t found it yet, keep looking. Don’t settle.',
    user: {
      name: 'Sunday Okpolufe',
      role: 'Senior Mobile Developer',
    },
  },
  {
    id: 1,
    logo: Enyata,
    comment: 'Paul is dilligent and effecient in his work.',
    user: {
      name: 'Leticia Esiagu',
      role: 'Backend Developer',
    },
  },
  {
    id: 2,
    logo: Uba,
    comment:
      'He is a great guy, always ready to work and go beyond his limits. I enjoy working with him.',
    user: {
      name: 'Syfon Issac',
      role: 'Frontend Developer',
    },
  },
  {
    id: 3,
    logo: Bookingsafrica,
    comment:
      'Paul has an amazing eye for details when it comes to design, he takes initiative on his own. He is respectful and excellent at interpreting UI designs. He makes the UX of a design enjoyable.  I am confident that he can meet any challenge you place before him, and I believe you would be exceedingly pleased with his work should you choose to hire him.',
    user: {
      name: 'Modupe Odumosu',
      role: 'Operations Manager',
    },
  },
];

export default COMMENT_DATA;
