import React, { useRef, useEffect } from 'react';
import hoverEffect from 'hover-effect';

const ProjectCard = ({ imageUrl, imageUrl2, title, caption, link, stack }) => {
  const container = useRef();

  useEffect(() => {
    new hoverEffect({
      parent: container.current,
      intensity: 0.3,
      image1: imageUrl,
      image2: imageUrl2,
      displacementImage:
        'https://raw.githubusercontent.com/robin-dela/hover-effect/master/images/heightMap.png',
    });
  }, [imageUrl, imageUrl2]);

  return (
    <div className="lg:mt-6 mb-10 overflow-hidden rounded-2xl">
      <div
        ref={container}
        className="h-72 md:h-96 xl:h-100 bg-gray-300 rounded-tl-xl rounded-tr-xl overflow-hidden object-top canva"
      ></div>
      <div className="px-6 xl:px-10 py-8 bg-blue-dark">
        <div className="space-y-4">
          <h1 className="text-xl xl:text-2xl font-bold text-gray-300">
            {title}
          </h1>
          <p className="text-sm xl:text-lg text-gray-200">{caption}</p>
          <p className="text-sm xl:text-lg font-bold text-gray-200">
            Tech Stack:
            {stack.map((item, idx) => (
              <span key={idx} className="mx-1">
                {item},
              </span>
            ))}
          </p>
        </div>
        {link ? (
          <div className="mt-10">
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="bg-gray-200 text-sm md:text-base px-5 py-2"
            >
              View
            </a>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
