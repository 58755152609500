import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import ScrollToTop from './ScrollToTop';

import './styles/base.scss';
import RouteChangeTracker from './plugins/analytic';

import Loader from './components/Loader';
import Cursor from './components/Cursor';
import HomePage from './pages/Index';
import AboutPage from './pages/About';
import SkillsPage from './pages/Skills';
import WorkPage from './pages/Work';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => {
  const location = useLocation();
  return (
    <div>
      <ScrollToTop />
      <RouteChangeTracker />
      <Cursor />
      <Loader />
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/skills" component={SkillsPage} />
          <Route exact path="/works" component={WorkPage} />
        </Switch>
      </AnimatePresence>
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
