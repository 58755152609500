import React from 'react';

import Navbar from './Navbar';

const BaseHeader = ({ title, caption }) => (
  <aside>
    <header className="bg-black relative">
      <div className="container z-10 mx-auto px-5 sm:px-10">
        <div className="scroll absolute bottom-28 -right-5 lg:right-2 transform rotate-90">
          <h1 className="text-white text-sm lg:text-base">
            Scroll Down <span className="ml-3">&#8594;</span>
          </h1>
        </div>
        <Navbar type="white" className="pt-10" />
        <div className="py-64 lg:py-52 xl:py-80 2xl:pt-80 2xl:pb-100">
          <div className="space-y-8 text-white text-center">
            <div className="text-5xl xl:text-7xl font-serif font-bold">
              {title}
            </div>
            <p className="text-lg lg:text-xl xl:text-2xl relative font-serif z-10 text-grey text-line">
              <span className="bg-black px-5 py-6 uppercase">{caption}</span>
            </p>
          </div>
        </div>
      </div>
    </header>
  </aside>
);

export default BaseHeader;
