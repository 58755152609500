import React from 'react';

import { ReactComponent as Wand } from '../assets/svgs/Quicks/wand.svg';
import { ReactComponent as Chat } from '../assets/svgs/Quicks/chat.svg';
import { ReactComponent as Team } from '../assets/svgs/Quicks/team.svg';

const icons = [Wand, Chat, Team];

const QuirkList = ({ quirk: { id, title, caption } }) => {
  const Icon = icons[id];
  return (
    <div className="mt-8 xl:mt-16">
      <div className="inline-flex self-center gap-x-8 xl:gap-x-10">
        <div>
          <div className="bg-gray-200 w-16 h-16 xl:w-20 xl:h-20 rounded-full">
            <Icon className="mx-auto relative top-5 xl:top-7" />
          </div>
        </div>
        <div className="space-y-2 lg:space-y-3 xl:max-w-lg">
          <h2 className="text-xl xl:text-2xl font-serif font-semibold">
            {title}
          </h2>
          <p className="text-sm xl:text-lg">{caption}</p>
        </div>
      </div>
    </div>
  );
};

export default QuirkList;
