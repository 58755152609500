import Chefruntown from '../../assets/images/mockups/chefruntown-desktop-mockup.webp';
import ChefruntownTablet from '../../assets/images/mockups/chefruntown-tablet-mockup.webp';
import Geoplex from '../../assets/images/mockups/geoplex-mockup.webp';
import GeoplexTv from '../../assets/images/mockups/geoplex-tv-mockup.webp';
import Kiitec from '../../assets/images/mockups/kiitec-mockup.webp';
import KiitecMobile from '../../assets/images/mockups/kiitec-mobile-mockup.webp';
import BossBus from '../../assets/images/mockups/bossbus-mockup.webp';
import BossBusDesktop from '../../assets/images/mockups/bossbus-desktop.webp';
import BookingsAfrica from '../../assets/images/mockups/bookingsafrica-mockup.webp';
import BookingsAfricaEvents from '../../assets/images/mockups/video-call.webp';
import Haullers from '../../assets/images/mockups/haullers.webp';
import HaullersMobile from '../../assets/images/mockups/haullers-mobile.webp';

export const PROJECT_DATA = [
  {
    id: 0,
    imageUrl: Kiitec,
    imageUrl2: KiitecMobile,
    title: 'Kiitec',
    caption:
      'An E-commerce platform leveraging the 🇳🇬Nigerian market is a client-side web application that bridges the relation between clients and a retail vendors enabling ordering of retail market products as easy as a click of a button, migrated an existing Vuejs code to Nuxtjs improving load time and increased SEO',
    stack: ['NuxJs (Vue)', 'Bootstrap'],
    link: 'https://kiitec.com/',
  },
  {
    id: 1,
    imageUrl: BossBus,
    imageUrl2: BossBusDesktop,
    title: 'BossBus',
    caption:
      'A logistics online option that offers clients various means of transportation services across Nigeria',
    stack: ['NexJs (React)', 'Tailwindcss'],
    link: 'https://bossbusworld.com/',
  },
  {
    id: 2,
    imageUrl: BookingsAfrica,
    imageUrl2: BookingsAfricaEvents,
    title: 'BookingsAfrica',
    caption:
      'An online platform spread across Nigeria, Kenya and South Africa for hiring talents(e.g engineers, tutor, chef, plumber etc) for a period of time. The video conference feature is robust enough to accommodate over 100 people, hence can be used for master classes, seminars and events.',
    stack: ['VueJs', 'Twillo', 'Tailwindcss', 'Vue Web Sockets'],
    link: 'https://sandbox.bookingsafrica.com/events',
  },
  {
    id: 3,
    imageUrl: HaullersMobile,
    imageUrl2: Haullers,
    title: 'HaullersOnline',
    caption:
      'A robust logistics management service system formed in Dubai that handles transportation of heavy duty products/goods shipped from other countries to Nigeria',
    stack: ['HTML/SASS', 'Boostrap', 'Javascript'],
    link: 'https://haullersonline.com/',
  },
];

export const PORTFOLIO_DATA = [
  {
    id: 0,
    imageUrl: ChefruntownTablet,
    imageUrl2: Chefruntown,
    title: 'Chefruntown',
    caption:
      'An E-commerce platform based in the 🇺🇸US where certified chefs display dishes & recipes across the globe, with the PAY4ME loan feature enabling clients to purchase descent meals for friends and family',
    stack: ['NuxJs (Vue)', 'Bootstrap'],
  },
  {
    id: 1,
    imageUrl: BossBusDesktop,
    imageUrl2: BossBus,
    title: 'BossBus',
    caption:
      'A logistics online option that offers clients various means of transportation services across Nigeria',
    stack: ['NexJs (React)', 'Tailwindcss'],
    link: 'https://bossbusworld.com/',
  },
  {
    id: 2,
    imageUrl: KiitecMobile,
    imageUrl2: Kiitec,
    title: 'Kiitec',
    caption:
      'An E-commerce platform leveraging the 🇳🇬Nigerian market is a client-side web application that bridges the relation between clients and a retail vendors enabling ordering of retail market products as easy as a click of a button, migrated an existing Vuejs code to Nuxtjs improving load time and increased SEO',
    stack: ['NuxJs (Vue)', 'Bootstrap'],
    link: 'https://kiitec.com/',
  },
  {
    id: 3,
    imageUrl: Geoplex,
    imageUrl2: GeoplexTv,
    title: 'Geoplex',
    caption:
      'An oil drilling service industry that offers different services to about 30+ partners across the globe',
    stack: ['HTML/SASS', 'Bootstrap', 'Javascript'],
    link: 'https://www.geoplex.com/',
  },
  {
    id: 2,
    imageUrl: HaullersMobile,
    imageUrl2: Haullers,
    title: 'HaullersOnline',
    caption:
      'A robust logistics management service system formed in Dubai that handles transportation of heavy duty products/goods shipped from other countries to Nigeria',
    stack: ['VueJs', 'Boostrap', 'Rest Countries API'],
    link: 'https://haullersonline.com/',
  },
  {
    id: 3,
    imageUrl: BookingsAfricaEvents,
    imageUrl2: BookingsAfrica,
    title: 'BookingsAfrica',
    caption:
      'An online platform spread across Nigeria, Kenya and South Africa for hiring talents(e.g engineers, tutor, chef, plumber etc) for a period of time. The video conference feature is robust enough to accommodate over 100 people, hence can be used for master classes, seminars and events.',
    stack: ['VueJs', 'Twillo', 'Tailwindcss', 'Vue Web Sockets'],
    link: 'https://sandbox.bookingsafrica.com/events',
  },
];
