import { ReactComponent as Git } from '../../assets/svgs/Skills/git.svg';
import { ReactComponent as ReactLogo } from '../../assets/svgs/Skills/react.svg';
import { ReactComponent as Vuejs } from '../../assets/svgs/Skills/vuejs.svg';
import { ReactComponent as Figma } from '../../assets/svgs/Skills/figma.svg';
import { ReactComponent as Html } from '../../assets/svgs/Skills/html.svg';
import { ReactComponent as Css } from '../../assets/svgs/Skills/css.svg';
import { ReactComponent as Sass } from '../../assets/svgs/Skills/sass.svg';
import { ReactComponent as Netlify } from '../../assets/svgs/Skills/netlify.svg';
import { ReactComponent as Xd } from '../../assets/svgs/Skills/xd.svg';
import { ReactComponent as Trello } from '../../assets/svgs/Skills/trello.svg';
import { ReactComponent as Js } from '../../assets/svgs/Skills/javascript.svg';
import { ReactComponent as Bootstrap } from '../../assets/svgs/Skills/bootstrap.svg';
import { ReactComponent as Sb } from '../../assets/svgs/Skills/storybook.svg';

const SKILL_DATA = [
  {
    name: 'Git',
    icon: Git,
  },

  {
    name: 'React',
    icon: ReactLogo,
  },

  {
    name: 'Vuejs',
    icon: Vuejs,
  },

  {
    name: 'Html',
    icon: Html,
  },

  {
    name: 'Css',
    icon: Css,
  },

  {
    name: 'Sass',
    icon: Sass,
  },

  {
    name: 'Netlify',
    icon: Netlify,
  },

  {
    name: 'Xd',
    icon: Xd,
  },
  {
    name: 'Trello',
    icon: Trello,
  },
  {
    name: 'Javascript',
    icon: Js,
  },
  {
    name: 'Bootstrap',
    icon: Bootstrap,
  },
  {
    name: 'Storybook',
    icon: Sb,
  },
  {
    name: 'Figma',
    icon: Figma,
  },
];

export default SKILL_DATA;
