import React from 'react';

import Pearl from '../assets/images/pearl.webp';
import QuirkList from './QuirkList';

const myQuirks = [
  {
    id: 0,
    title: 'Problem Solving',
    caption: 'A problem is a chance for me to do my best.',
  },
  {
    id: 1,
    title: 'Excellent Communication',
    caption:
      'No one can whistle a symphony. It takes a whole orchestra to play it.',
  },
  {
    id: 2,
    title: 'Team Player',
    caption:
      'Teamwork begins by building trust. And the only way to do that is to overcome our need for invulnerability.',
  },
];

const Quirks = () => (
  <div className="bg-gray-300 quicks">
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="overflow-hidden">
        <img
          src={Pearl}
          alt="man holding pearl"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="py-10 xl:py-24 container mx-auto px-5 sm:px-10 lg:px-16 xl:px-20">
        <div className="xl:max-w-2xl mx-auto">
          <h1 className="text-3xl lg:text-4xl xl:text-5xl font-serif font-bold">
            My Quirks
          </h1>
          {myQuirks.map((quirk) => (
            <QuirkList key={quirk.id} quirk={quirk} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Quirks;
