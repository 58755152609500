import React from 'react';

const SkillCard = ({ skill: { icon, name } }) => {
  const Icon = icon;
  return (
    <div className="w-24 py-4 rounded-lg text-center">
      <Icon className="mx-auto h-12 md:h-16 xl:h-auto" />
      <p className="text-white uppercase mt-3 md:mt-5 text-xs md:text-sm xl:text-xl">
        {name}
      </p>
    </div>
  );
};

export default SkillCard;
