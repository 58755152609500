const Heading = ({ className, title, children }) => (
  <div className={className}>
    <p className="text-grey text-sm xl:text-lg font-bold uppercase">{title}</p>
    <h1 className="text-3xl sm:text-4xl xl:text-5xl xl:leading-snug font-bold font-serif leading-snug mt-4 text-white">
      {children}
    </h1>
  </div>
);

Heading.defaultProps = {
  title: '',
};

export default Heading;
