import React from 'react';
import { motion } from 'framer-motion';

import Header from '../components/Header';
import Works from '../components/works/Works';
import Projects from '../components/projects/Projects';
import CTA from '../components/CTA';

const HomePage = () => (
  <motion.div
    initial={{ y: 0 }}
    animate={{ y: 0 }}
    exit={{ y: -40, opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Header />
    <Works />
    <Projects />
    <CTA />
  </motion.div>
);

export default HomePage;
