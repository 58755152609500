import React from 'react';
import { ReactComponent as Quotes } from '../../assets/svgs/quote-sm.svg';

const CommentCard = ({ logo, comment, user }) => (
  <div className="bg-white p-10 mb-6 transform hover:-rotate-3 hover:scale-y-110 duration-200 hover:shadow-xl rounded-md lg:mt-10">
    <img src={logo} alt={comment} className="h-10" />
    <div className="mt-10 inline-flex self-center gap-x-3">
      <div className="w-4">
        <Quotes />
      </div>
      <p className="text-black text-sm sm:text-lg">{comment}</p>
    </div>
    <div className="pt-8">
      <p className="text-sm sm:text-lg font-bold text-black">{user.name}</p>
      <span className="text-sm sm:text-sm text-grey">{user.role}</span>
    </div>
  </div>
);

export default CommentCard;
