import React, { useState } from 'react';
import { init, sendForm } from 'emailjs-com';
import { useForm } from 'react-hook-form';
import isEmail from 'is-email';

import Heading from './Heading';
import FormInput from './FormInput';
import ErrorMessage from './ErrorMessage';
import Button from './Button';

const Contact = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [contactNumber, setContactNumber] = useState('000000');
  const [statusMessage, setStatusMessage] = useState('Message');

  const message = watch('message') || '';
  const messageCharsLeft = 1500 - message.length;

  const generateContactNumber = () => {
    const numStr = '000000' + ((Math.random() * 1000000) | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  };

  const onSubmit = (e) => {
    init('user_gS13h4mGlXPu5wNlA7XTf');
    const statusMessage = document.querySelector('.status-message');

    const setClassName = (name) => {
      statusMessage.className = name;
    };

    const timer = () => {
      setTimeout(() => {
        return setClassName('status-message');
      }, 5000);
    };

    generateContactNumber();
    setStatusMessage('Sending Message...');
    setClassName('status-loading');

    sendForm('default_service', 'template_tch7zmb', '#contact-form').then(
      () => {
        setStatusMessage('Message sent!');
        setClassName('status-message success');
        timer();
        reset();
      },
      (error) => {
        setStatusMessage('Failed to send message! Please try again later.');
        setClassName('status-message failure');
        timer();
        console.log('FAILED...', error);
      }
    );
  };

  return (
    <div id="contact" className="bg-black xl:pt-10 pb-20 md:pb-36">
      <div className="container px-5 sm:px-10 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="hidden lg:block">
            <Heading>Hire Me</Heading>
            <p className="text-gray-200 max-w-xl text-sm xl:text-lg mt-4">
              Now that you know a lot about me, let me know if you are
              interested to work with me.
            </p>
          </div>
          <div className="mt-10 lg:px-8 xl:px-12">
            <p
              id="#status-message"
              className="status-message text-lg text-center"
            >
              {statusMessage}
            </p>
            <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <FormInput
                type="text"
                label="name"
                {...register('name', {
                  required: 'Enter your full name',
                })}
              >
                <ErrorMessage errors={errors} name="name" />
              </FormInput>

              <FormInput
                type="email"
                label="email"
                {...register('email', {
                  required: 'Enter your email address',
                  validate: (value) =>
                    isEmail(value) || 'enter a valid email address',
                })}
                errors={errors}
              >
                <ErrorMessage errors={errors} name="email" />
              </FormInput>
              <FormInput
                label="contact_number"
                placeholder={contactNumber}
                className="hidden"
                errors={errors}
              />
              <FormInput
                as="textarea"
                type="textarea"
                label="message"
                {...register('message', {
                  required: 'You missed this field',
                })}
                errors={errors}
                messageCharsLeft={messageCharsLeft}
              >
                <ErrorMessage errors={errors} name="message" />
              </FormInput>
              <Button theme="light" className="w-full mt-3 md:mt-0">
                Send Message
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
